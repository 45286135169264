import {createRouter, createWebHistory} from 'vue-router'
import login from '../views/login'
import p302 from '../views/302.vue'
import utils from "@/utils/utils.js";
import layout from '../components/layout';
import DbConfig from '../views/DbConfig'
import MonitoringPanel from "../views/MonitoringPanel"
import CounterSystem from "../views/MonitoringPanel/CounterSystem"
import DatabaseNav from "../views/MonitoringPanel/CounterSystem/DatabaseNav"

import ResourcePromotion from '../views/ResourcePromotion'
import ResourcePromotionManagement from '../views/ResourcePromotion/ResourcePromotionManagement'
import PromotionAmbassador from '../views/ResourcePromotion/PromotionAmbassador'
import SilentExposure from '../views/ResourcePromotion/SilentExposure'

import PersonnelManagement from '../views/PersonnelManagement'
import RegisteredUsers from "../views/PersonnelManagement/RegisteredUsers"
import LibrarianManagement from "../views/PersonnelManagement/LibrarianManagement"
import AdminManagement from "../views/PersonnelManagement/AdminManagement"
import ReadingPromotionCenter from '../views/ReadingPromotionCenter'
import ReadPromotionalReports from '../views/ReadingPromotionCenter/ReadPromotionalReports'
import Home from '../views/Home'
import DbNavManage from '../views/DbConfig/DbNavManage'
import DbNavList from '../views/DbConfig/DbNavManage/DbNavList'
import UserPermissions from '../views/DbConfig/DbNavManage/UserPermissions'
import UserResponsibility from '../views/DbConfig/DbNavManage/UserResponsibility'

import CopyrightNotice from '../views/DbConfig/DbNavManage/CopyrightNotice'
import GlobalSetting from '../views/DbConfig/DbNavManage/GlobalSetting'
import AbnormalMonitoring from '../views/DbConfig/DbNavManage/AbnormalMonitoring'
import DetailSetting from '../views/DbConfig/DbNavManage/DbNavList/DetailSetting'
import BaseInfo from '../views/DbConfig/DbNavManage/DbNavList/DetailSetting/BaseInfo'
import SiteSetting from '../views/DbConfig/DbNavManage/DbNavList/DetailSetting/SiteSetting'
import Attachment from '../views/DbConfig/DbNavManage/DbNavList/DetailSetting/Attachment'
import CatalogingLogs from '../views/DbConfig/DbNavManage/DbNavList/DetailSetting/CatalogingLogs'
import HelpDocument from '../views/DbConfig/DbNavManage/DbNavList/DetailSetting/HelpDocument'
import NotificationSetting from '../views/DbConfig/DbNavManage/DbNavList/DetailSetting/NotificationSetting'
import NavSetting from '../views/DbConfig/DbNavManage/DbNavList/DetailSetting/NavSetting'
import ChildRelate from '../views/DbConfig/DbNavManage/DbNavList/DetailSetting/ChildRelate'
import notFound from "@/views/notFound/404.vue"

import store from '@/store/index.js';

const routes = [
    {
        path:"/",
        name:"/",
        component: layout,
        // redirect: 'dbConfig',
        children: [

            // {
            //     parentName:"/",
            //     path: '/index',
            //     name:"index",
            //     redirect: 'dbConfig',
            // },
            {   
                path: '/readingPromotionCenter',
                name: '阅读推广中心',
                component: ReadingPromotionCenter,
                redirect: '/readingPromotionCenter/readPromotionalReports',
                children: [
                    {
                         parentName:"阅读推广中心",
                        path: 'readPromotionalReports',
                        name: '阅读推广报告',
                        component: ReadPromotionalReports,
                    },
                ]
            },


            // {
            //     path: '/index',
            //     redirect: 'dbConfig',
            // },
            // {
            //     path: '/home',
            //     component: Home,
            // },
            // {
            //     path: '/dbConfig',
            //     component: DbConfig,
            //     redirect: '/dbConfig/dbNavManage',
            //     children: [
            //         {
            //             path: 'dbNavManage',
            //             component: DbNavManage,
            //             redirect: '/dbConfig/dbNavManage/dbNavList',
            //             children: [
            //                 {
            //                     path: 'dbNavList',
            //                     component: DbNavList,
            //                 },
            //                 {
            //                     path: 'UserPermissions',
            //                     component: UserPermissions,
            //                 },
            //                 {
            //                     path: 'UserResponsibility',
            //                     component: UserResponsibility,
            //                 },
            //                 {
            //                     path: 'copyrightNotice',
            //                     component: CopyrightNotice,
            //                 },
            //                 {
            //                     path: 'globalSetting',
            //                     component: GlobalSetting,
            //                 },
            //             ]
            //         },
            //         {
            //             path: 'detailSetting',
            //             component: DetailSetting,
            //             redirect: '/dbConfig/detailSetting/baseInfo',
            //             children: [
            //                 {
            //                     path: 'baseInfo',
            //                     component: BaseInfo,
            //                 },
            //                 {
            //                     path: 'childRelate',
            //                     component: ChildRelate,
            //                 },
            //                 {
            //                     path: 'navSetting',
            //                     component: NavSetting,
            //                 },
            //                 {
            //                     path: 'notificationSetting',
            //                     component: NotificationSetting,
            //                 },
            //                 {
            //                     path: 'siteSetting',
            //                     component: SiteSetting,
            //                 },
            //                 {
            //                     path: 'helpDocument',
            //                     component: HelpDocument,
            //                 },
            //                 {
            //                     path: 'attachment',
            //                     component: Attachment,
            //                 },
            //                 {
            //                     path: 'catalogingLogs',
            //                     component: CatalogingLogs,
            //                 },
            //             ]
            //         },
            //     ]
            // },
            // {
            //     path: '/monitoringPanel',
            //     component: MonitoringPanel,
            //     redirect: '/monitoringPanel/counterSystem',
            //     children: [
            //         {
            //             path:"counterSystem",
            //             component: CounterSystem,
            //             redirect: '/monitoringPanel/counterSystem/databaseNav',
            //             children: [
            //                 {
            //                     path:"databaseNav",
            //                     component: DatabaseNav,
            //                 }
            //             ]
            //         }
            //     ]
            // },
            // {
            //     path: '/personnelManagement',
            //     name: '人员管理',
            //     component: PersonnelManagement,
            //     redirect: '/personnelManagement/registeredUsers',
            //     children: [
            //         {
            //             path: 'registeredUsers',
            //             name: '注册用户',
            //             component: RegisteredUsers,
            //         },
            //         {
            //             path: 'librarianManagement',
            //             name: '馆员管理',
            //             component: LibrarianManagement,
            //         },
            //         {
            //             path: 'adminManagement',
            //             name: '后台人员管理',
            //             component: AdminManagement,
            //         },
            //     ]
            // },
            // {
            //     path: '/readingPromotionCenter',
            //     name: '阅读推广中心',
            //     component: ReadingPromotionCenter,
            //     redirect: '/readingPromotionCenter/readPromotionalReports',
            //     children: [
            //         {
            //             path: 'readPromotionalReports',
            //             name: '阅读推广报告',
            //             component: ReadPromotionalReports,
            //         },
            //     ]
            // },
            // {
            //     path: '/resourcePromotion',
            //     name: '资源推广',
            //     component: ResourcePromotion,
            //     redirect: '/resourcePromotion/silentExposure',
            //     children: [
            //         {
            //             path: 'silentExposure',
            //             name: '沉寂曝光',
            //             component: SilentExposure,
            //         },
            //         {
            //             path: 'resourcePromotionManagement',
            //             name: '推广管理',
            //             component: ResourcePromotionManagement,
            //         },
            //         {
            //             path: 'promotionAmbassador',
            //             name: '推广大使',
            //             component: PromotionAmbassador,
            //         },
            //     ]
            // },
        ]
    },
    {
        path: '/login',
        component: login
    },
    {
        path: '/302',
        component: p302
    },
    {
        path: '/404',
        component: notFound
    }
    // if you omit the last `*`, the `/` character in params will be encoded when resolving or pushing
    // { path: '/:pathMatch(.*)', name: 'bad-not-found', component: NotFound },
    
]


export const DynamicRouting = {
            
            '/home':{
                parentName:"/",
                path: '/home',
                name:'home',
                component: Home,
            },
            '/dbConfig':{
                parentName:"/",
                path: '/dbConfig',
                name:"dbConfig",
                component: DbConfig,
                redirect: '/dbConfig/dbNavManage',
                children: [
                    {
                        path: '/dbConfig/dbNavManage',
                        parentName:"dbConfig",
                        name:"dbNavManage",
                        component: DbNavManage,
                        redirect: '/dbConfig/dbNavManage/dbNavList',
                        children: [
                            {
                                parentName:"dbNavManage",
                                path: '/dbConfig/dbNavManage/dbNavList',
                                component: DbNavList,
                            },
                            {
                                parentName:"dbNavManage",
                                path: '/dbConfig/dbNavManage/UserPermissions',
                                component: UserPermissions,
                            },
                            {
                                parentName:"dbNavManage",
                                path: '/dbConfig/dbNavManage/UserResponsibility',
                                component: UserResponsibility,
                            },
                            {
                                parentName:"dbNavManage",
                                path: '/dbConfig/dbNavManage/copyrightNotice',
                                component: CopyrightNotice,
                            },
                            {
                                parentName:"dbNavManage",
                                path: '/dbConfig/dbNavManage/globalSetting',
                                component: GlobalSetting,
                            },
                            {
                                parentName:"dbNavManage",
                                path: '/dbConfig/dbNavManage/AbnormalMonitoring',
                                component: AbnormalMonitoring,
                            },
                        ]
                    },
                    {
                        path: '/dbConfig/detailSetting',
                        parentName:"dbConfig",
                        name:'detailSetting',
                        component: DetailSetting,
                        redirect: '/dbConfig/detailSetting/baseInfo',
                        children: [
                            {
                                parentName:"detailSetting",
                                path: '/dbConfig/detailSetting/baseInfo',
                                component: BaseInfo,
                            },
                            {
                                parentName:"detailSetting",
                                path: '/dbConfig/detailSetting/childRelate',
                                component: ChildRelate,
                            },
                            {
                                parentName:"detailSetting",
                                path: '/dbConfig/detailSetting/navSetting',
                                component: NavSetting,
                            },
                            {
                                parentName:"detailSetting",
                                path: '/dbConfig/detailSetting/notificationSetting',
                                component: NotificationSetting,
                            },
                            {
                                parentName:"detailSetting",
                                path: '/dbConfig/detailSetting/siteSetting',
                                component: SiteSetting,
                            },
                            {
                                parentName:"detailSetting",
                                path: '/dbConfig/detailSetting/helpDocument',
                                component: HelpDocument,
                            },
                            {
                                parentName:"detailSetting",
                                path: '/dbConfig/detailSetting/attachment',
                                component: Attachment,
                            },
                            {
                                parentName:"detailSetting",
                                path: '/dbConfig/detailSetting/catalogingLogs',
                                component: CatalogingLogs,
                            },
                        ]
                    },
                ]
            },
            '/monitoringPanel':{
                path: '/monitoringPanel',
                parentName:"/",
                name:"monitoringPanel",
                component: MonitoringPanel,
                redirect: '/monitoringPanel/counterSystem',
                children: [
                    {
                        parentName:"monitoringPanel",
                        path:"/monitoringPanel/counterSystem",
                        name:"counterSystem",
                        component: CounterSystem,
                        redirect: '/monitoringPanel/counterSystem/databaseNav',
                        children: [
                            {
                                parentName:"counterSystem",
                                path:"/monitoringPanel/counterSystem/databaseNav",
                                component: DatabaseNav,
                            }
                        ]
                    }
                ]
            },
            '/personnelManagement':{
                path: '/personnelManagement',
                parentName:"/",
                name: '人员管理',
                component: PersonnelManagement,
                redirect: '/personnelManagement/registeredUsers',
                children: [
                    {
                         parentName:"人员管理",
                        path: '/personnelManagement/registeredUsers',
                        name: '注册用户',
                        component: RegisteredUsers,
                    },
                    {
                         parentName:"人员管理",
                        path: '/personnelManagement/librarianManagement',
                        name: '馆员管理',
                        component: LibrarianManagement,
                    },
                    {
                         parentName:"人员管理",
                        path: '/personnelManagement/adminManagement',
                        name: '后台人员管理',
                        component: AdminManagement,
                    },
                ]
            },
            '/resourcePromotion':{
                 parentName:"/",
                path: '/resourcePromotion',
                name: '资源推广',
                component: ResourcePromotion,
                redirect: '/resourcePromotion/silentExposure',
                children: [
                    {
                         parentName:"资源推广",
                        path: '/resourcePromotion/silentExposure',
                        name: '沉寂曝光',
                        component: SilentExposure,
                    },
                    {
                          parentName:"资源推广",
                        path: '/resourcePromotion/resourcePromotionManagement',
                        name: '推广管理',
                        component: ResourcePromotionManagement,
                    },
                    {
                          parentName:"资源推广",
                        path: '/resourcePromotion/promotionAmbassador',
                        name: '推广大使',
                        component: PromotionAmbassador,
                    },
                ]
            }
            
        }
export const notFoundRoute =  { path: '/:pathMatch(.*)*', name: 'not-found', component: notFound }

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (['/login', '/302', '/401', '/404'].includes(to.path)) {
        return next();
    }
    let token = localStorage.getItem("accessToken");
    if (utils.isBlank(token)) {
        return next(`/login?redirect=${to.fullPath}`);
    } else {
        let path = to.path;
        if(store.getters.getAddRoutes?.length){
             if(path !== '/'){
                next()
             }else{
                next(store.getters.getAddRoutes[0].path)
             }
        }else{
             store.dispatch('permission/generateRoutes').then(res => {
                if(res?.length){
                    res.forEach( val => {
                        router.addRoute( val.parentName , val )
                    })

                    router.addRoute( { path: '/:pathMatch(.*)*', name: 'not-found', component:() => import('@/views/notFound/404.vue') })
                }
                if(path !== '/'){
                   next({ ...to, replace: true })
                }else{
                   next({path:store.getters.getAddRoutes[0].path , replace: true })
                }
                
            }).catch(err => {
                next()
            })
        }

    }
});

// router.beforeEach((to, from, next) => {
//     if (['/login', '/302', '/401', '/404'].includes(to.path)) {
//         return next();
//     }
//     let token = localStorage.getItem("accessToken");
//     if (utils.isBlank(token)) {
//         return next(`/login?redirect=${to.fullPath}`);
//     } else {
//         return next()
//     }
// })

export default router
