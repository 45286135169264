<template>
  <el-container class="layout-container">
    <el-aside width="250px">
      <el-scrollbar style="padding: 0 16px 0 38px" class="background-img">
        <logo/>
        <nav-bar/>
        <!-- <div class="bg-img"/> -->
      </el-scrollbar>
    </el-aside>
    <el-container class="content-container">
      <div class="user-info flex">
        <el-dropdown>
          <div class="flex">
            <el-avatar size="small" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
            <div style="line-height: 24px;margin-left: 8px;">
              {{userInfo.userName}}
            </div>
          </div>

          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-main style="padding:0;height: calc(100vh - 200px);overflow: hidden">
        <router-view/>
      </el-main>
      <el-footer
          style="font-size: 12px;height: 30px;line-height: 30px;box-sizing: content-box;background-color: white;text-align: center">
        Copyright ©2024.南京昆虫软件有限公司, All rights reserved .备案号：苏ICP备11031502号-5
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import {loginOut} from "@/js/network/sso"
import {checkLogin} from "@/js/network/sso";
import {ref} from 'vue'
import Logo from './components/Logo'
import NavBar from './components/NavBar'
import {mapState} from "vuex";
import {MessageBox} from "@/js/utils/commonJs";

const item = {
  date: '2016-05-02',
  name: 'Tom',
  address: 'No. 189, Grove St, Los Angeles',
}

export default {
  name: 'Layout',
  components: {
    Logo,
    NavBar
  },
  data() {
    return {
      tableData: [],
      item: {
        date: '2016-05-02',
        name: 'Tom',
        address: 'No. 189, Grove St, Los Angeles',
      },
      userInfo:{}
    };
  },
  methods: {
    logout(){
      loginOut().then(res => {
        this.$router.push("/login");
      }).catch(error => {

      });
    },
    getUserInfo(){
      checkLogin().then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        this.userInfo = res.data;

      }).catch(error => {

      });
    }
  },
  created() {
    this.tableData = ref(Array.from({length: 20}).fill(item))
    this.getUserInfo()
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.layout-container {
  height: 100vh;
  background: linear-gradient(123deg, #E2E9FF 0%, #ACC4FF 100%);
  
  .background-img{
    background-image: url('https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/bg-img.png');
    background-position: right bottom;
    background-size:auto 400px;
    background-repeat: no-repeat;
  }

  .bg-img {
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url('https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/bg-img.png');
    background-size: 100% auto;
    width: 100%;
    height: 400px;
    background-position: bottom;
    background-repeat: no-repeat;
  }
}

.user-info {
  position: absolute;
  right: 50px;
  top: 24px;
  cursor: pointer;
}

:deep(.el-tooltip__trigger:focus-visible) {
  outline: unset;
}
.content-container{
  min-width: 1030px;
}
//:deep(.el-scrollbar__view){
//  height: 100%;
//}
</style>
